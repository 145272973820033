var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2024-11-16-21"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const { sentryBaseConfig } = require("@novel/shared/utils/sentryBaseConfig");

Sentry.init({
    ...sentryBaseConfig,
    dsn:
        process.env.SENTRY_DSN ||
        process.env.NEXT_PUBLIC_SENTRY_DSN ||
        "https://ad7679f4ef23460199938eb448a907c5@o1158538.ingest.sentry.io/6270191",
    project: "customer-pass",
});
